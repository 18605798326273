.linkTo {
	.linkTo-icon {
		display: inline-block;
		transition: all 0.2s;
	}
	&:hover {
		text-decoration: none;
		.linkTo-icon {
			transform: translateX(10px);
		}
	}
}

.react-tabs__tab-list {
	list-style: none;
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 0;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.6);
	flex-wrap: wrap;
	.react-tabs__tab {
		padding: 1rem 2rem;
		border-bottom: solid 2px transparent;
		cursor: pointer;
		&:hover,
		&.react-tabs__tab--selected {
			color: #000;
			border-bottom: solid 2px #000;
		}
	}
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
	padding: 24px 0;
}
.react-tabs__tab-panel--selected {
	padding: 64px 0;
}
.react-tabs-vertical {
	.react-tabs__tab-list {
		display: block;
		.react-tabs__tab {
			padding: 16px 0;
			&:hover,
			&.react-tabs__tab--selected {
				color: #000;
				border-bottom: solid 2px transparent;
			}
		}
		box-shadow: none;
	}
}
*:focus {
	box-shadow: none !important;
	outline: none;
}

.text-hover-unstyled:hover {
	text-decoration: none;
}

.table th, .table td {
	vertical-align: middle;
}

.swal2-styled.swal2-confirm {
	background-color: var(--action);
}
.swal2-icon {
	color: var(--action) !important;
	border-color: var(--action) !important;
}
